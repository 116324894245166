.card {
	overflow: hidden;
	background: var(--card-background);
	border-radius: var(--card-border-radius);
	display: flex;
	flex-direction: column;
	composes: resetLink from "../../styles/_utils.module.scss";
	&:hover {
		.image {
			transform: scale(1.05);
		}
	}
}
.contentAboveImage {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.content {
	padding: 1rem;
}
.headerLabel {
	color: var(--card-sub-headline-color);
	font-weight: 700;
	font-size: 0.75rem;
	letter-spacing: 0.05em;
	margin-bottom: 0.5rem;
}
.title {
}
.subTitle {
	color: var(--card-sub-headline-color);
	font-weight: 600;
}
.image {
	transition: all 0.5s;
}
