.slider {
	margin-bottom: 2rem;
}
.sliderWithOverflow {
	overflow: visible !important;
	:global {
		.swiper-wrapper {
			overflow: visible;
		}
	}
}
