.dot {
	position: absolute;
	width: 2rem;
	height: 2rem;
	border-radius: 2em;
	background: var(--hotspot-dot-background);
	color: var(--hotspot-dot-color);
	display: flex;
	outline: none;
	border: none;
	cursor: pointer;
	&:after,
	&:before {
		content: "";
		width: 0.75em;
		height: 0.1em;
		background: currentColor;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) rotate(0deg);
	}
	&:before {
		transform: translate(-50%, -50%) rotate(90deg);
	}
}
