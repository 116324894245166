.section {
	margin: 0 auto;
	max-width: var(--container-width);
	padding: 3rem var(--container-spacing);
	width: 100%;
	display: flex;
}
.noHorizontalSpacing {
	--container-spacing: 0;
}
.sm {
	max-width: var(--container-width-sm);
}
.lg {
	max-width: var(--container-width-lg);
}
.xl {
	max-width: var(--container-width-xl);
}
.hstart {
	justify-content: start;
}
.hcenter {
	justify-content: center;
}
.hend {
	justify-content: end;
}
.vstart {
	align-items: start;
}
.vcenter {
	align-items: center;
}
.vend {
	align-items: end;
}
.fullWidth {
	max-width: unset;
}
.fullHeight {
	min-height: calc(100vh - var(--header-height));
	min-height: calc(100svh - var(--header-height));
}
.content {
	max-width: 100%;
}
.contentFullWidth {
	width: 100%;
}
