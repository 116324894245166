.scrollSpyNav {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	height: 100svh;
	display: flex;
	align-items: center;
	padding: 1rem;
}
.scrollSpyList {
	@util reset-list;
}
.scrollSpyLink {
	composes: resetLink from "../../styles/_utils.module.scss";
}
