.input {
	display: inline-flex;
	align-items: center;
	justify-content: start;
	background: var(--input-background);
	color: var(--input-color);
	border: 1px solid var(--input-border-color);
	padding: var(--input-padding);
	height: var(--input-height);
	border-radius: var(--input-radius);
	border-color: var(--input-border-color);
	width: 100%;
	min-width: var(--input-min-width);
	border-width: var(--input-border-width);
	border-style: solid;
	&:focus {
		outline: none;
	}
}
